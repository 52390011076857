<template>
  <div>
    <h3 ref="intro">
      What is a Straight Line ?
    </h3>
    <br>
    <p>
      Straight line is one of the simplest shapes in geometry. <br> <br> One of the features of a straight line is that
      <i>all the points lying a straight line are aligned in the same direction </i>.
      Stated in other words, a straight line has a constant slope.<br><br>
      Imagine two points \(P_1 = (x, y)\) and \(P_2 = (x_0, y_0) \) that lie on a straight line. Then a constant slope implies
      $$  \frac{y -y_0}{x - x_0} = \text{a constant (say S)}$$
      This tells us that if we have the slope of a straight line and know a point in space that the line passes through, then we can obtain the equation of that line.
    </p>
    <h3 ref="sol">
      Equation of a Straight Line: Slope & Intercept Form
    </h3>
    <br>
    <p>
      Let's consider a straight line that has a slope \(M\) and intercepts the y-axis at point \(P=(0, C) \).
      Thus, substituting \(x_0 = 0\), \(y_0 = C\), and \(S = M\) we obtain the equation of the line as
      $$ \frac{y-C}{x} = M $$
      Upon a little rearrangement we can write
      $$ y = Mx + C$$
    </p>
    <h3 ref="graph2">
      MagicGraph | Slope & Intercept of a Straight Line
    </h3>
    <br>
    <h5>Getting Started</h5>
    <p>
      This MagicGraph offers a visual + interactive illustration of the slope-intercept form of the equation of a straight line.
      You will learn to draw a straight line based on its slope and y-intercept. <br><br>
      Enter the values of M and C in the equation field to draw the straight line represented by this equation.
    </p>
    <h5>Icons on the MagicGraph</h5>
    <p>
      This MagicGraph contains the following two icons: <br>
      <i class="eraser ma-1" /> Tap on this button to erase all entries and start over. <br>
      <i class="stater ma-1" /> Tap on this button to see the data and parameters of the illustration. <br>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'StraightLine',
  created: function () {
    this.$store.commit('navigation/resetState');
    // Store mutations
    let title = 'Straight Line';
    this.$store.commit('navigation/changeTitle', 'Intersection of Two Lines');
    this.$store.commit('navigation/changeMenu', 'Intersection of Two Lines');
    let newTopics = [
      {title: 'Straight Line', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'MagicGraph: Shortest Path Connecting Two Points', img:'/assets/touch.svg', action: () => this.goto('graph1')},
      {title: 'Equation of Straight Line', img:'/assets/number-2.svg', action: () => this.goto('sol')},
      {title: 'MagicGraph: Equation of a Straight Line ', img:'/assets/touch.svg', action: () => this.goto('graph2')}
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo: {
          title: 'Intersection of Lines',
        //  titleTemplate: '%s - Learn interactively',
          meta: [
                  {vmid: 'description', name: 'description', content: 'Learn interactively about intersection of  lines.'}
                ]
   },
}
</script>
